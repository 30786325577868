/* Add these imports in your main CSS file or in Home.js */
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* CSS animations for fade-in effects */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.animate-fade-in {
  opacity: 0;
  animation: fadeIn 1s forwards;
}

.animate-fade-in.delay-1s {
  animation-delay: 1s;
}

